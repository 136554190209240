import moment from 'moment'
import React, { KeyboardEvent, useEffect, useState } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import MembershipData from '../../../../entities/MembershipData'
import { RequestGetter } from '../../../../helpers/request'
import { calculateLength, isIdentification, keyPressIdentification } from '../../../../utils/validateIdentification'
import { BackButton, ButtonContainer, CustomButton, InputContainer, StyledError } from '../../../Collapse/stepped/style'
import { DIGITS, IDENTIFICATION_OPTIONS, MONTHS } from '../../constants'
import { StyledDatePicker } from '../../style'
import { IdentificationType } from '../../types'
import { Check, Extra, FormInputContainerBlock, StyledInputStep, StyledLabelStep, SubTitle, Title } from '../style'
import { StyledDatePickerHeader } from './style'

const GRANDETABLE_CARD = require('../../../../assets/images/general/grandetable-card.png')
const EMAIL_ICON = require('../../../../assets/images/general/mail-icon.svg')
const PHONE_ICON = require('../../../../assets/images/general/phone-icon.svg')
const CALENDAR_ICON = require('../../../../assets/images/general/calendar-icon.svg')

const CustomInput = (props: any): JSX.Element => <StyledInputStep readOnly={true} onClick={props.onClick} value={props.value} placeholder="dd-mm-aaaa" />

type Step2Props = {
  membershipData: MembershipData
  setMembershipData: (membershipData: MembershipData) => void
  setStep: (step: number) => void
}

type StepErrors = {
  identificationType?: string
  identification?: string
  name?: string
  lastName?: string
  phone?: string
  email?: string
  birthday?: string
}

type BirthdayDate = {
  birthdayMobile?: Date | string
  selectedMonth?: { value: number; label: string }
  selectedYear?: { value: number; label: number } | null
}

export default function Step2(props: Step2Props) {
  const { membershipData, setMembershipData, setStep } = props
  const [selectedMonth, setSelectedMonth] = useState<{ value: number; label: string }>(MONTHS[0])
  const [errors, setErrors] = useState<StepErrors>({})
  const [selectedIdentificationType, setSelectedIdentificationType] = useState<IdentificationType>({ value: 'C', label: 'Cédula' })
  const [customerIdentification, setCustomerIdentification] = useState('')
  const [isIdentificationCorrect, setisIdentificationCorrect] = useState(false)
  const [isNameCorrect, setIsNameCorrect] = useState(false)
  const [customerName, setCustomerName] = useState('')
  const [isLastNameCorrect, setIsLastNameCorrect] = useState(false)
  const [customerLastName, setCustomerLastName] = useState('')
  const [customerPhone, setCustomerPhone] = useState('')
  const [customerEmail, setCustomerEmail] = useState('')
  const [years, setYears] = useState<{ value: number; label: number }[]>([])
  const [selectedYear, setSelectedYear] = useState<{ value: number; label: number } | null>()
  const [currentBirthday, setCurrentBirthday] = useState<Date | string>('')
  const [currentBirthdayMobile, setCurrentBirthdayMobile] = useState<Date | string>('')

  useEffect(() => {
    populateYears()
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    populateFields()
  }, [membershipData])

  const populateYears = () => {
    const topYear = retrieveTopYear().getFullYear()
    const minYear = retrieveMinYear().getFullYear()
    const years: { value: number; label: number }[] = []
    for (let i = minYear; i <= topYear; i++) {
      years.push({ value: i, label: i })
    }
    years.reverse()
    setYears(years)
    setSelectedYear(years[0])
  }

  const retrieveMinYear = (): Date => moment().subtract(99, 'years').toDate()
  const retrieveTopYear = (): Date => moment().subtract(18, 'years').toDate()

  const populateFields = () => {
    if (membershipData) {
      const { name, lastName, identification, identificationType, phone, email, birthday, birthdayMobile, selectedMonth, selectedYear, urlReference } = membershipData
      identificationType &&
        setSelectedIdentificationType({
          value: identificationType,
          label: isIdentification(identificationType) ? 'Cédula' : 'Pasaporte',
        })
      identification && setCustomerIdentification(identification)
      name && setCustomerName(name)
      lastName && setCustomerLastName(lastName)
      phone && setCustomerPhone(phone)
      email && setCustomerEmail(email)
      setCurrentBirthday(obtainBirthday(birthdayMobile, birthday, urlReference))
      setBirthdayDate({ birthdayMobile, selectedMonth, selectedYear })
    }
  }

  const obtainBirthday = (birthdayMobile: string, birthday: Date, urlReference: string): string | Date => {
    if (urlReference) {
      return moment(birthdayMobile.toString()).toDate()
    }
    return birthday
  }

  const setBirthdayDate = (props: BirthdayDate) => {
    const { birthdayMobile, selectedMonth, selectedYear } = props
    if (birthdayMobile === undefined) {
      populateYears()
      setSelectedMonth(MONTHS[0])
      birthdayMobile && setCurrentBirthdayMobile(birthdayMobile)
      return
    }
    selectedMonth && setSelectedMonth(selectedMonth)
    setSelectedYear(selectedYear)
    setCurrentBirthdayMobile(moment(birthdayMobile.toString()).format('YYYY-MM-DD'))
  }

  const validateForm = () => {
    let result: boolean = true
    let formErrors: StepErrors = {}

    if (customerName.length < 1) {
      formErrors.name = 'Campo Requerido'
      result = false
    }

    if (customerLastName.length < 1) {
      formErrors.lastName = 'Campo Requerido'
      result = false
    }

    if (customerPhone.length < 1) {
      formErrors.phone = 'Campo Requerido'
      result = false
    } else if (!/[0-9]{10}$/.test(customerPhone)) {
      formErrors.phone = 'El número de teléfono debe contener máximo 10 dígitos.'
      result = false
    }

    if (customerEmail.length < 1) {
      formErrors.email = 'Campo Requerido'
      result = false
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(customerEmail)) {
      formErrors.email = 'El email no es válido.'
      result = false
    }

    if (currentBirthday) {
      if (!validateDate(currentBirthday)) {
        formErrors.birthday = 'El rango de fecha es incorrecto.'
        result = false
      }
    }

    setErrors(formErrors)
    return result
  }

  const handleKeyPressPhone = (event: KeyboardEvent<HTMLInputElement>) => {
    const pressedKey = event.key
    const regex = new RegExp(`${DIGITS}$`)

    if (regex.test(pressedKey)) {
      setCustomerPhone(event.currentTarget.value)
      return
    }
    event.preventDefault()
  }

  const handleBirthdayChange = (selectedDate: moment.Moment | null) => {
    if (selectedDate) {
      let customerBirthday = moment(selectedDate).month(selectedMonth.label)
      if (selectedYear) {
        customerBirthday = moment(customerBirthday).year(selectedYear.value)
      }
      setCurrentBirthdayMobile(customerBirthday.format('YYYY-MM-DD'))
      setCurrentBirthday(customerBirthday.toDate())
    }
  }

  const handleBirthdayMobileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value.toString() != '') {
      const dateFormat = new Date(e.target.value)
      setCurrentBirthdayMobile(moment(dateFormat.toString()).format('YYYY-MM-DD'))
      setCurrentBirthday(moment(dateFormat).add(1, 'd').toDate())
      validateDate(dateFormat)
    }
  }

  const validateDate = (date: Date) => {
    let formErrors: StepErrors = {}
    let result: boolean = true
    if (date < retrieveMinYear() || date > retrieveTopYear()) {
      formErrors.birthday = 'El rango de fecha es incorrecto.'
      result = false
    }
    setErrors(formErrors)
    return result
  }

  const submitForm = () => {
    if (validateForm()) {
      handleNextStep()
    }
  }

  const handleNextStep = () => {
    const params = {
      name: customerName,
      lastName: customerLastName,
      identificationType: selectedIdentificationType.value,
      idn: customerIdentification,
      birthday: currentBirthday ? currentBirthday : '1900-01-15T05:00:00.000Z',
      phone: customerPhone,
      email: customerEmail,
    }

    RequestGetter('POST', 'member-register', params)
      .then(() => {
        setMembershipData({
          ...membershipData,
          name: customerName,
          lastName: customerLastName,
          email: customerEmail,
          phone: customerPhone,
          birthday: currentBirthday,
          birthdayMobile: currentBirthday,
          selectedMonth: selectedMonth,
          selectedYear: selectedYear,
        })
        setStep(3)
      })
      .catch((error: any) => {
        if (error.response.status == 409) {
          toast('Usted ya se encuentra registrado.')
          return
        } else if (error.response.status == 401) {
          toast('Cédula no válida. Intente nuevamente.')
          return
        }
        toast('Ocurrió un error al intentar registrarse.')
      })
  }

  return (
    <>
      <Title>Datos de contacto</Title>
      <SubTitle>Recibirás notificación sobre descuentos, eventos, promociones y más.</SubTitle>
      <FormInputContainerBlock>
        <StyledLabelStep>Tipo de Identificación</StyledLabelStep>
        <InputContainer>
          <Select
            placeholder="Seleccione Tipo"
            isSearchable={false}
            error={errors.identificationType !== ''}
            options={IDENTIFICATION_OPTIONS}
            styles={{
              control: (base) => ({ ...base, color: 'white' }),
              option: (base) => ({ ...base, color: 'black' }),
            }}
            className="select-control-container"
            classNamePrefix="select-control"
            onChange={(currentType: IdentificationType) => {
              setSelectedIdentificationType(currentType)
            }}
            value={selectedIdentificationType}
            isDisabled={true}
          />
          {errors.identificationType !== '' && <StyledError>{errors.identificationType !== ''}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <FormInputContainerBlock>
        <StyledLabelStep>Número de identificación</StyledLabelStep>
        <InputContainer>
          <StyledInputStep
            placeholder="Ingresa el número de identificación"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomerIdentification(e.target.value)}
            error={errors.identification !== undefined && errors.identification != ''}
            maxLength={calculateLength(selectedIdentificationType.value).max}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => keyPressIdentification(e, selectedIdentificationType.value)}
            readOnly
            disabled={true}
            value={customerIdentification}
          />
          <Check visible={isIdentificationCorrect} />
          {errors.identification && <StyledError>{errors.identification}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <FormInputContainerBlock>
        <StyledLabelStep>Nombre</StyledLabelStep>
        <InputContainer>
          <StyledInputStep
            placeholder="Ingresa el nombre"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomerName(e.target.value)}
            error={errors.name !== undefined && errors.name != ''}
            onKeyUp={() => setIsNameCorrect(customerName.length >= 2)}
            value={customerName}
          />
          <Check visible={isNameCorrect} />
          {errors.name && <StyledError>{errors.name}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <FormInputContainerBlock>
        <StyledLabelStep>Apellido</StyledLabelStep>
        <InputContainer>
          <StyledInputStep
            placeholder="Ingresa el apellido"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomerLastName(e.target.value)}
            error={errors.lastName !== undefined && errors.lastName != ''}
            onKeyUp={() => setIsLastNameCorrect(customerLastName.length >= 2)}
            value={customerLastName}
          />
          <Check visible={isLastNameCorrect} />
          {errors.lastName && <StyledError>{errors.lastName}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <FormInputContainerBlock>
        <StyledLabelStep>Teléfono de contacto</StyledLabelStep>
        <InputContainer>
          <StyledInputStep
            placeholder="Ingresa un teléfono de contacto"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomerPhone(e.target.value)}
            onKeyPress={handleKeyPressPhone}
            error={errors.phone !== undefined && errors.phone != ''}
            maxLength={10}
            value={customerPhone}
          />
          <Extra image={PHONE_ICON} />
          {errors.phone && <StyledError>{errors.phone}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <FormInputContainerBlock>
        <StyledLabelStep>Correo electrónico</StyledLabelStep>
        <InputContainer>
          <StyledInputStep
            placeholder="Ingresa el correo electrónico"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomerEmail(e.target.value)}
            error={errors.email !== undefined && errors.email != ''}
            value={customerEmail}
          />
          <Extra image={EMAIL_ICON} />
          {errors.email && <StyledError>{errors.email}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <FormInputContainerBlock className="d-none d-md-block">
        <StyledLabelStep>Fecha de nacimiento</StyledLabelStep>
        <InputContainer maxWidth="55%">
          <StyledDatePicker
            renderCustomHeader={({ changeYear, changeMonth }: { date: moment.Moment; changeYear: (value: number) => void; changeMonth: (value: number) => void }): JSX.Element => (
              <StyledDatePickerHeader>
                <Select
                  isSearchable={false}
                  options={MONTHS}
                  className="select-control-container"
                  classNamePrefix="select-control"
                  onChange={(month: { label: string; value: number }) => {
                    changeMonth(month.value)
                    setSelectedMonth(month)
                  }}
                  value={selectedMonth}
                />
                <Select
                  isSearchable={false}
                  options={years}
                  className="select-control-container"
                  classNamePrefix="select-control"
                  onChange={(year: { label: number; value: number }) => {
                    changeYear(year.value)
                    setSelectedYear(year)
                  }}
                  value={selectedYear}
                />
              </StyledDatePickerHeader>
            )}
            placeholderText="dd-mm-aaaa"
            dateFormat="dd-MM-yyyy"
            minDate={moment(retrieveMinYear())}
            maxDate={moment(retrieveTopYear())}
            selected={currentBirthday}
            onChange={handleBirthdayChange}
            error={errors.birthday !== undefined}
            locale="es"
            customInput={<CustomInput />}
            value={currentBirthday}
          />
          <Extra image={CALENDAR_ICON} />
          {errors.birthday && <StyledError>{errors.birthday}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <FormInputContainerBlock className="d-md-none d-sm-block">
        <StyledLabelStep>Fecha de nacimiento</StyledLabelStep>
        <InputContainer maxWidth="60%">
          <StyledInputStep
            type="date"
            placeholder="dd-mm-aaaa"
            format="dd-MM-yyyy"
            onBlur={handleBirthdayMobileChange}
            error={errors.birthday !== undefined}
            defaultValue={currentBirthdayMobile}
            customInput={<CustomInput />}
          />
          <Extra image={CALENDAR_ICON} />
          {errors.birthday && <StyledError>{errors.birthday}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <ButtonContainer>
        <CustomButton id="GRTB_MBR_P1_SIG" className="GRTB_SLEC_MEMB" onClick={submitForm}>
          Continuar
        </CustomButton>
        <BackButton id="GRTB_MBR_P2_REG" onClick={() => setStep(1)}>
          REGRESAR
        </BackButton>
      </ButtonContainer>
    </>
  )
}
