import { push } from 'gatsby'
import React, { useEffect } from 'react'
import { ButtonContainer, CustomButton } from '../../../Collapse/stepped/style'
import { InfoContainer, SubTitle, WithoutPContainer, Image } from '../style'

type FinalStepProps = {
  transactionStatus: string
  status: string
  setStep: (step: number) => void
  setLoading: (loading: boolean) => void
}
const GRANDETABLE_CARD = require('../../../../assets/images/general/grandetable-card.png')

export default function FinalStep(props: FinalStepProps) {
  const { setStep, setLoading, transactionStatus, status } = props

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <InfoContainer className="row">
      <WithoutPContainer className="col-lg col-md col-sm" style={{ textAlign: 'left' }}>
        <SubTitle>
          {status ? status : 'Proceso terminado exitosamente.'}
        </SubTitle>
        <ButtonContainer>
          <CustomButton
            onClick={() => {
              transactionStatus == 'APPROVED' ? push('/benefits') : setStep(5)
              setLoading(true)
            }}
          >
            {transactionStatus == 'APPROVED'
              ? 'Ver los Beneficios'
              : 'Realizar pago nuevamente'}
          </CustomButton>
        </ButtonContainer>
      </WithoutPContainer>
    </InfoContainer>
  )
}