import React, { Component } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { Arrow } from '../../../Generics/SliderArrow'
import { SliderContainer, SliderItem, Title, SectionText, InfoSide } from './style'
import { GRANDETABLE_CARD, CONTENT_BENEFITS } from '../../constants'

class MembershipSlider extends Component {
  render() {
    const settings = {
      infinite: true,
      fade: true,
      speed: 900,
      autoplay: true,
      autoplaySpeed: 5000,
      slidesToScroll: 1,
      pauseOnHover: true,
      arrows: true,
      nextArrow: <Arrow background="transparent" color="#c59d5f" />,
      prevArrow: <Arrow isPrev background="transparent" color="#c59d5f" />,
    }
    return (
      <SliderContainer>
        <div className="row">
          <div className="col-sm-12">
            <Slider {...settings}>
              <SliderItem key={0}>
                <img src={GRANDETABLE_CARD} style={{ height: '140px', marginTop: '22px' }} />
                <Title>Disfruta de las experiencias gastronómicas más exclusivas del país.</Title>
              </SliderItem>
              {CONTENT_BENEFITS.map((benefit, index) => (
                <SliderItem key={index}>
                  <img src={benefit.icon} style={{ height: '100px', marginTop: '45px' }}/>
                  <InfoSide>
                    <SectionText>{benefit.info}</SectionText>
                    {index === 0 && <SectionText style={{ marginTop: '20px' }}>*Aplican Condiciones</SectionText>}
                  </InfoSide>
                </SliderItem>
              ))}
              <SliderItem>
                <InfoSide>
                  <SectionText style={{ marginTop: '48px' }}>El Club Gourmet más exclusivo de Ecuador con el que podrás disfrutar de beneficios y descuentos todos los días en los mejores restaurantes del país.</SectionText>
                  <SectionText style={{ marginTop: '20px', fontWeight: 'bold' }}>Exclusivos para socios Diners Club</SectionText>
                </InfoSide>
              </SliderItem>
            </Slider>
          </div>
        </div>
      </SliderContainer>
    )
  }
}

export default MembershipSlider
