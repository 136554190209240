import es from 'date-fns/locale/es'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { registerLocale } from 'react-datepicker'
import { toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import MembershipData from '../../entities/MembershipData'
import { RequestGetter } from '../../helpers/request'
import withLocation from '../AuthSection/withLocation'
import { Image } from '../StylesPages/articleStyle'
import MembershipSlider from './components/MembershipSlider'
import Stepper from './components/Stepper'
import { HelmetComponent } from '../HelmetComponent'
import { GRANDETABLE_CARD, CONTENT_BENEFITS } from './constants'
import FinalStep from './steps/FinalStep'
import Step1 from './steps/Step1'
import Step2 from './steps/Step2'
import Step3 from './steps/Step3'
import Step4 from './steps/Step4'
import Step5 from './steps/Step5'
import {
  BenefitsContainer,
  ContainerFlex,
  FormContainer,
  HorizontalLine,
  ImageContainer,
  InfoContainer,
  InfoSection,
  InfoSide,
  InfoText,
  LoadingStep,
  SectionText,
  StepProgress,
  SubTitle,
  Title,
} from './style'
import { City } from './types'

const loadingGif = require('../../assets/images/general/loading-img.gif')
registerLocale('es', es)

declare const P: any
class MembershipForm extends Component {
  state = {
    membershipData: {
      name: '',
      lastName: '',
      phone: '',
      email: '',
      identification: '',
      identificationType: '',
      paymentType: '',
      birthday: '',
      selectedYear: null,
      selectedMonth: null,
      mainStreet: '',
      numeration: '',
      city: 0,
      secondaryStreet: '',
      reference: '',
      facName: '',
      facLastName: '',
      facEmail: '',
      facIdentification: '',
      facPhone: '',
      facIdentificationType: '',
      address: '',
      terms: false,
      isFormVisible: false,
      request: null,
      urlReference: null,
    },
    step: 1,
    membershipType: '',
    membershipId: '',
    loading: true,
    status: '',
    transactionStatus: '',
    cities: [],
  }

  componentDidMount = () => {
    const { search } = this.props
    if (search && search.reference) {
      this.setMembershipData({ ...this.setMembershipData, urlReference: search.reference })
      this.setState({ step: 6 })
      setTimeout(() => {
        this.getCustomerData(search.reference)
      }, 1000)
    }

    this.getCities()
    this.setInitialValues()
  }

  initP2P = (processUrl: any) => {
    P.init(processUrl, {
      opacity: 0.4,
    })
  }

  setMembershipData = (membershipData: MembershipData) => {
    this.setState({ membershipData })
  }

  setStep = (step: number) => {
    this.setState({ step })
  }

  setLoading = (loading: boolean) => {
    this.setState({ loading })
  }

  setCities = (cities: City[]) => {
    this.setState({ cities })
  }

  getCities = () => {
    RequestGetter('post', 'cities-by-module', { membership: true }).then((response) => {
      const cityResponse = response.data
      const cities = cityResponse.map((cityItem: any) => ({ value: cityItem.id, label: cityItem.name }))
      cities.sort(function (a: City, b: City) {
        return a.value > b.value ? 1 : a.value < b.value ? -1 : 0
      })
      this.setCities(cities)
    })
  }

  setInitialValues = () => {
    setTimeout(() => {
      try {
        P.on('response', (o: any) => {
          const transactionStatus = o.status.status
          if ('APPROVED' == transactionStatus) {
            this.statusApproved(transactionStatus)
          } else if ('PENDING' == transactionStatus) {
            this.statusPending(transactionStatus)
          } else {
            this.statusRejected(o)
          }
        })
      } catch (error) {
        console.log(error)
      }
    }, 1000)
  }

  statusRejected = (o: any) => {
    RequestGetter('POST', 'membership/cancel', {
      reference: o.reference,
      requestId: o.requestId,
      status: o.status.status,
      message: o.status.message,
      reason: o.status.reason,
    }).then((data: any) => {
      this.setState({ status: data.data.message, transactionStatus: o.status.status })
      this.setStep(5)
      toast('Pago cancelado o rechazado, intentar nuevamente')
    })
  }

  statusPending = (transactionStatus: any) => {
    this.setState({
      status: 'Tu pago está siendo procesado, pronto recibirás un email con el estado de tu suscripción.',
      transactionStatus: transactionStatus,
    })
    this.setStep(6)
  }

  statusApproved = (transactionStatus: any) => {
    this.collect(null)
    this.setState({ transactionStatus: transactionStatus })
  }

  handleLoading = () => {
    const { loading } = this.state
    this.setLoading(!loading)
  }

  setPaymentSummary = (data: any) => {
    const { membershipData } = this.state
    const { membership, customer, invoice } = data
    this.setMembershipData({
      ...membershipData,
      name: customer.name,
      lastName: customer.last_name,
      email: customer.email,
      identification: customer.idn,
      phone: customer.phone,
      identificationType: customer.identification_type ? customer.identification_type : 'C',
      city: customer.city_id,
      birthday: customer.birth_date,
      birthdayMobile: customer.birth_date,
      mainStreet: customer.main_street,
      secondaryStreet: customer.secondary_street,
      numeration: customer.numeration,
      reference: customer.reference,
      address: invoice.address,
      facEmail: invoice.invoice_email,
      facIdentification: invoice.invoice_idn,
      facIdentificationType: invoice.invoice_identification_type,
      facLastName: invoice.invoice_last_name,
      facName: invoice.invoice_name,
      facPhone: invoice.invoice_phone,
      paymentType: membership.type,
      terms: customer.terms == 1 ? true : false,
    })
  }

  getCustomerData = (reference: any) => {
    RequestGetter('GET', 'customer-data/' + reference).then((data: any) => {
      const { membership } = data.data
      this.setState({ customerData: data.data })
      this.setPaymentSummary(data.data)

      membership.status != 'APPROVED' ? this.cancelTransaction(membership, reference) : this.setState({ reference: reference, step: 6 })
      this.collect(reference)
    })
  }

  cancelTransaction = (membership: any, reference: any) => {
    this.setState({ reference: reference, step: 5 })
    this.statusRejected({
      reference,
      requestId: membership.request_id,
      status: membership.status,
      message: 'Cancelado por el Usuario',
      reason: 'Cancelado por el Usuario',
    })
  }

  collect = (reference: any) => {
    const { membershipData } = this.state
    const { terms, paymentType, request } = membershipData
    const params = {
      reference,
      terms: terms,
      req: request,
      paymentType: paymentType,
      isUpdate: false,
    }
    try {
      RequestGetter('POST', 'generateTransaction', params).then((data: any) => {
        let transactionStatus = data.data.status.status
        let analyticId = ''
        let message = 'Por favor inténtalo nuevamente.'
        this.handleLoading()
        this.setStep(6)
        this.setState({ transactionStatus })
        const reponse: { [key: string]: void } = {
          REJECTED: this.setState({ status: 'Tu suscripción ha sido rechazada, por favor verifica tu medio de pago.' }),
          PENDING: this.setState({ status: 'Tu pago está siendo procesado, pronto recibirás un email con el estado de tu suscripción.' }),
          APPROVED: this.setState({
            status: '¡Suscripción exitosa! ' + 'Te damos la bienvenida a Grande Table. En los próximos días nos contactaremos contigo para hacer la entrega de tu tarjeta y puedas  disfrutar de todos los beneficios que te ofrece este espacio gastronómico.',
          }),
        }
        reponse[transactionStatus]
      })
    } catch (error) {
      console.error(error)
      toast(error)
    }
  }
  static propTypes: { search: PropTypes.Requireable<object> }

  render() {
    const { step, membershipData, cities, loading, transactionStatus, status } = this.state
    return (
      <>
        <HelmetComponent
          title="Membresías - Grande Table"
          description="Con tu membresía Grande Table podrás ser parte del club gastronómico más exclusivo del país y disfrutar de la gastronomía del más alto nivel."
        />
        <InfoContainer className="row">
          <div className="col-md-5 col-lg-5 col-xs-12 offset-lg-4 offset-md-4" style={{ display: 'block', marginBottom: '15px' }}>
            <StepProgress currentStep={step} />
            <Stepper step={step} />
            <HorizontalLine />
          </div>
        </InfoContainer>
        {(step === 1 || step === 6) && <MembershipSlider/>}
        <InfoContainer className="row">
          <BenefitsContainer className="col-lg-7 col-md-7 col-sm-12">
            <Image src={GRANDETABLE_CARD} />
            <Title>Disfruta de las experiencias gastronómicas más exclusivas del país.</Title>
            <InfoContainer>
              <InfoText>
                El Club Gourmet más exclusivo de Ecuador con el que podrás disfrutar de beneficios y descuentos todos los días en los mejores restaurantes del país.
              </InfoText>
              <ContainerFlex>
                {CONTENT_BENEFITS.map((benefit, index) => (
                  <InfoSection key={index}>
                    <ImageContainer>
                      <img src={benefit.icon} />
                    </ImageContainer>
                    <InfoSide>
                      <SectionText>{benefit.info}</SectionText>
                      {index === 0 && <SectionText style={{ marginTop: '20px' }}>*Aplican Condiciones</SectionText>}
                    </InfoSide>
                  </InfoSection>
                ))}
              </ContainerFlex>
              <SubTitle>Exclusivos para socios Diners Club</SubTitle>
            </InfoContainer>
          </BenefitsContainer>
          <FormContainer className="col-lg-5 col-md-5 col-sm-12">
            {step === 1 && <Step1 membershipData={membershipData} setMembershipData={this.setMembershipData} setStep={this.setStep} />}
            {step === 2 && <Step2 membershipData={membershipData} setMembershipData={this.setMembershipData} setStep={this.setStep} />}
            {step === 3 && <Step3 membershipData={membershipData} setMembershipData={this.setMembershipData} setStep={this.setStep} cities={cities} />}
            {step === 4 && <Step4 membershipData={membershipData} setMembershipData={this.setMembershipData} setStep={this.setStep} />}
            {step === 5 && (
              <Step5
                membershipData={membershipData}
                setMembershipData={this.setMembershipData}
                setStep={this.setStep}
                cities={cities}
                setLoading={this.setLoading}
                initP2P={this.initP2P}
              />
            )}
            {step === 6 &&
              (loading ? (
                <LoadingStep>
                  <img src={loadingGif} alt="" />
                </LoadingStep>
              ) : (
                <FinalStep setStep={this.setStep} setLoading={this.setLoading} status={status} transactionStatus={transactionStatus} />
              ))}
          </FormContainer>
        </InfoContainer>
      </>
    )
  }
}

MembershipForm.propTypes = {
  search: PropTypes.object,
}

export default withLocation(MembershipForm)
