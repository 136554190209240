import React from 'react'
import { Check, HeadContainer, Number, StepperHeader, Title, TitleContainer } from './style'

type StepItemProps = {
  isOpen: boolean
  numList: number
  currentStep: number
}
export default function StepItem(props: StepItemProps) {
  const { isOpen, numList, currentStep } = props

  return (
    <div>
      <StepperHeader>
        <HeadContainer isOpen={isOpen} checked={currentStep > numList}>
          {currentStep <= numList ? <Number isOpen={isOpen}>{numList}</Number> : <Check />}
        </HeadContainer>
      </StepperHeader>
    </div>
  )
}
