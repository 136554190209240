export const IDENTIFICATION_OPTIONS = [
  { value: 'C', label: 'Cédula' },
  { value: 'P', label: 'Pasaporte' },
]
export const FAC_IDENTIFICATION_OPTIONS = [
  { value: 'C', label: 'Cédula' },
  { value: 'P', label: 'Pasaporte' },
  { value: 'R', label: 'RUC' },
]

export const DIGITS = '[0-9]'
export const ALPHA_NUMS = '[0-9a-zA-Z]'
export const DIGITS_ONLY = new RegExp(`${DIGITS}$`)
export const ALPHA_NUMERIC_ONLY = new RegExp(`${ALPHA_NUMS}$`)

export const MONTHS: { value: number; label: string }[] = [
  { value: 0, label: 'Enero' },
  { value: 1, label: 'Febrero' },
  { value: 2, label: 'Marzo' },
  { value: 3, label: 'Abril' },
  { value: 4, label: 'Mayo' },
  { value: 5, label: 'Junio' },
  { value: 6, label: 'Julio' },
  { value: 7, label: 'Agosto' },
  { value: 8, label: 'Septiembre' },
  { value: 9, label: 'Octubre' },
  { value: 10, label: 'Noviembre' },
  { value: 11, label: 'Diciembre' },
]