import axios from 'axios'
import { navigate } from 'gatsby'
import React, { useState } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import MembershipData from '../../../../entities/MembershipData'
import { BackButton, ButtonContainer, CustomButton, InputContainer, StyledError, StyledInput } from '../../../Collapse/stepped/style'
import { ALPHA_NUMERIC_ONLY, DIGITS_ONLY, IDENTIFICATION_OPTIONS } from '../../constants'
import { IdentificationType } from '../../types'
import { calculateLength, isIdentification, isPassport, keyPressIdentification, validateIdentification } from '../../../../utils/validateIdentification'
import { Check, FormInputContainerBlock, StyledLabelStep, SubTitle, Title } from '../style'
import { BoxWrapper, TextSection } from './style'

const GRANDETABLE_CARD = require('../../../../assets/images/general/grandetable-card.png')

type Step1Props = {
  membershipData: MembershipData
  setMembershipData: (membershipData: MembershipData) => void
  setStep: (step: number) => void
}

type StepErrors = {
  selectedIdentificationType?: string
  identification?: string
  paymentType?: string
}

export default function Step1(props: Step1Props) {
  const { membershipData, setMembershipData, setStep } = props
  const [selectedIdentificationType, setSelectedIdentificationType] = useState<IdentificationType>({
    value: 'C',
    label: 'Cédula',
  })
  const [identification, setIdentification] = useState(membershipData ? membershipData.identification : '')
  const [paymentType, setPaymentType] = useState(membershipData ? membershipData.paymentType : '')
  const [showCheck, setShowCheck] = useState(false)
  const [errors, setErrors] = useState<StepErrors>({})

  const testIdentification = (): boolean =>
    (identification !== undefined && isIdentification(selectedIdentificationType.value) && DIGITS_ONLY.test(identification) && checkLength(identification, 10, 10)) ||
    (identification !== undefined && isPassport(selectedIdentificationType.value) && ALPHA_NUMERIC_ONLY.test(identification) && checkLength(identification, 4, 16))

  const validateForm = () => {
    let result: boolean = true
    let formErrors: StepErrors = {}

    if (paymentType === undefined || paymentType == '') {
      formErrors.paymentType = 'Debes elegir una opción para continuar'
      result = false
    }

    if (selectedIdentificationType.value == '') {
      formErrors.selectedIdentificationType = 'Campo Requerido'
      result = false
    }

    if (identification === undefined || identification == '') {
      formErrors.identification = 'Campo Requerido'
      result = false
    } else {
      const validIdentification = validateIdentification(identification, selectedIdentificationType.value)
      if (validIdentification.error){
        formErrors.identification = validIdentification.message
        result = false
      }
    }

    setErrors(formErrors)
    return result
  }

  const checkLength = (identification: string, minlength: number, maxlength: number) => {
    return identification.length >= minlength && identification.length <= maxlength
  }

  const submitForm = () => {
    if (validateForm()) {
      handleNextStep()
    }
  }

  const handleNextStep = () => {
    try {
      axios
        .get(`${process.env.API_URL}/member-validator/${identification}`)
        .then((response: any) => response.data)
        .then((data) => {
          const { name, last_name, email, phone, identification } = data
          if (Object.keys(data).length == 0) {
            setMembershipData(fillNewMembershipData())
            return
          }
          setMembershipData({ ...membershipData, name, lastName: last_name, email, phone, identification, identificationType: selectedIdentificationType.value, paymentType })
        })
      setStep(2)
    } catch (error) {
      console.error(error)
      toast(error)
    }
    setMembershipData(fillNewMembershipData())
  }

  const fillNewMembershipData = (): MembershipData => ({
    name: '',
    lastName: '',
    email: '',
    phone: '',
    identification,
    identificationType: selectedIdentificationType.value,
    paymentType,
  })

  return (
    <>
      <TextSection>Membresía</TextSection>
      <Title>Selecciona tu membresía</Title>
      <SubTitle>Elige el pago que más se adapta a ti.</SubTitle>
      <BoxWrapper>
        <div id="GRTB_MBR_AN" className={paymentType === 'Annual' ? 'pressed' : ''} style={{ cursor: 'pointer' }} onClick={() => setPaymentType('Annual')}>
          <h3>Membresía anual</h3>
          <p>$66.75</p>
          <span className="dot" />
        </div>
        <div id="GRTB_MBR_MEN" className={paymentType === 'Monthly' ? 'pressed' : ''} style={{ cursor: 'pointer' }} onClick={() => setPaymentType('Monthly')}>
          <h3>Membresía mensual</h3>
          <p>$5.65</p>
          <span className="dot" />
        </div>
      </BoxWrapper>
      <StyledError style={{ marginRight: '15px' }}>{errors.paymentType}</StyledError>
      <br />
      <Title>Ingresa tu número de identificación</Title>
      <SubTitle>Nos permite brindarte una atención más rápida.</SubTitle>
      <FormInputContainerBlock>
        <StyledLabelStep>Tipo de Identificación</StyledLabelStep>
        <InputContainer>
          <Select
            placeholder="Seleccione Tipo"
            isSearchable={false}
            options={IDENTIFICATION_OPTIONS}
            error={errors.selectedIdentificationType !== ''}
            styles={{
              control: (base) => ({ ...base, color: 'white' }),
              option: (base) => ({ ...base, color: 'black' }),
            }}
            className="select-control-container"
            classNamePrefix="select-control"
            onChange={(currentType: IdentificationType) => {
              setSelectedIdentificationType(currentType)
              setIdentification('')
            }}
            value={selectedIdentificationType}
          />
          {errors.selectedIdentificationType && <StyledError>{errors.selectedIdentificationType}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <FormInputContainerBlock>
        <StyledLabelStep>Número de identificación</StyledLabelStep>
        <InputContainer>
          <StyledInput
            placeholder="Ingresa el número de identificación"
            onChange={(e) => setIdentification(e.target.value)}
            error={errors.identification !== undefined && errors.identification != ''}
            minLength={calculateLength(selectedIdentificationType.value).min}
            maxLength={calculateLength(selectedIdentificationType.value).max}
            onKeyPress={(e) => keyPressIdentification(e, selectedIdentificationType.value)}
            onKeyUp={() => setShowCheck(testIdentification())}
            value={identification}
          />
          <Check visible={showCheck} />
          {errors.identification && <StyledError>{errors.identification}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <ButtonContainer>
        <CustomButton onClick={submitForm}>Continuar</CustomButton>
      </ButtonContainer>
    </>
  )
}
