import styled from 'styled-components'
import media from '../../styles/media'

export const Container = styled.div`
  flex: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
`

export const StyledRow = styled.div`
  flex: auto;
  min-height: 86vh;
  align-items: flex-start;
  margin-top: 10px;
  &.flex-dir {
    display: flex;
    flex-direction: column;
    ${media.md`
      flex-direction: row;
    `}
  }
`

export const TitleCard = styled.h1`
  font-family: 'DroidSerif';
  color: #c59d5f;
  text-align: center;
  font-weight: 400;
  margin: 10px 0rem 0.5rem;
`

export const FormContainer = styled.div`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  &.min-height-50 {
    min-height: 50vh;
  }
`

export const MobilBackground = styled.div`
  position: absolute;
  width: 100%;
  padding: 35px 55px;
  background-color: #ececec;
  border-radius: 0 0 30% 30%;
  ${media.sm`
    display: none;
  `}
`