import DatePicker from 'react-datepicker'
import styled from 'styled-components'
import media from '../../styles/media'
import theme from '../../styles/theme'

type StyledInputProps = {
  error?: boolean
}

export const P2PLogo = styled.img`
  max-width: 150px;
  margin-left: 10px;
  margin-bottom: 10px;
  ${media.md`  
    margin-bottom: 0;
  `};
`

export const CustomContainerButton = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
`

export const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 7px;
  color: #a1a1a1;
  font-size: 0.75rem;
  ${media.md`  
    margin-bottom: 0;
  `};
`
export const StyledDatePicker = styled(DatePicker)`
  flex: auto;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #cbcbcb;
  font-family: 'Roboto';
  padding: 0.8rem 1rem;
  font-size: 0.9rem;
  width: 100%;

  @-moz-document url-prefix() {
    margin-right: calc(1.4rem - 1px);
  }

  &:focus {
    border-color: #c59d5f;
    outline: none;
    box-shadow: 0px 0px 2px 0px #c59d5f;
  }
  &::placeholder {
    color: #cbcbcb;
  }

  ${(props: StyledInputProps) => {
    if (props.error) {
      return `
        border-color: red;
      `
    }
    return null
  }};
`

export const StyledCheckbox = styled.input`
  ${(props: StyledInputProps) => {
    if (props.error) {
      return `
        border-color: red;
      `
    }
    return null
  }};
`

export const StyledTextFinal = styled.h4`
  color: black;
`
export const LoadingStep = styled.div`
  color: black;
  text-align: center;
`

type StepProgressProps = {
  currentStep: number
}

export const StepProgress = styled.div<StepProgressProps>`
  background-color: #c79e5f;
  height: 1px;
  position: absolute;
  top: 35px;
  z-index: 2;
  left: 0;
  right: 0;
  margin-left: 12.5%;
  ${(props) => {
    return props.currentStep == 3 ? 'width:18.75%' : props.currentStep == 4 ? 'width:40%' : 'width:0'
  }};

  ${media.sm`
    margin-left: 10.5%;
  `};
`

export const HorizontalLine = styled.div`
  width: 65%;
  background-color: ${theme.lightGrey3};
  height: 1px;
  position: absolute;
  top: 35px;
  right: 0;
  left: 0;
  margin: 0 auto;
`


export const StyledDisclaimerDiners = styled.p`
  padding-bottom: 10px;
  font-family: 'Roboto';
  font-size: 0.9rem;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: ${theme.purpleBrown};
  margin: 2rem auto 0;
`

export const BrandsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const StyledLogo = styled.img`
  max-width: 100px;
  margin: 0 10px;
`

export const CollapseContainer = styled.div`
  border-top: 1px solid ${theme.camel};
  padding: 20px 0;
  ${media.md`
  display: none;
  `}
`

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: top;
  text-align: center;
  margin: 0 9px 0 0;
  padding: 30px 0 17px;
  width: 100%;
  border-radius: 10px;
  border: solid 1px ${theme.lightGrey3};

  ${media.md`
    width: 33.33%;
  `}
`

export const InfoSide = styled.div`
  display: flex;
  flex-direction: column;

  hr {
    width: 15%;
    border: 1px solid #ededed;
    margin: 5px 0 10px;
  }
`

export const SectionTitle = styled.div`
  color: ${theme.camel};
  font-family: 'SFProText';
  font-size: 12.5px;
  text-transform: uppercase;
  letter-spacing: 0.69px;
  padding-bottom: 5px;
`

export const SectionText = styled.div`
  color: ${theme.grey};
  font-family: SFProText;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`
export const BenefitsContainer = styled.div`
  padding: 40px 80px 40px 350px;
  border-radius: 0px 20px 0px;
  background-color: #f5f5f5;
  display: none;

  ${media.sm`
    display: block;
    padding-left: 50px;
  `}
  ${media.md`
    display: block;
    padding: 40px 80px 40px 200px;
  `}
  ${media.lg`
    display: block;
    padding: 40px 80px 40px 250px;
  `}
`

export const FormContainer = styled.div`
  padding: 20px 17px;

  ${media.xs`
    padding: 20px 17px;
  `}
  ${media.sm`
    padding-right: 50px;
  `}
  ${media.md`
    padding: 40px 150px 40px 25px;
  `}
  ${media.lg`
    padding: 40px 250px 40px 25px;
  `}
`

export const InfoContainer = styled.div`
  margin-top: 15px;
  margin-right: 0px;
  margin-left: 0px;
`

export const Title = styled.h2`
  color: ${theme.gold};
  font-family: SFProText;
  font-size: 20px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;

  ${media.sm`
    padding-bottom: 10px;
    margin-top: 0;
  `}
  ${media.md`
    padding-bottom: 15px;
    margin-top: 22px;
  `}
  ${media.lg`
    padding-bottom: 15px;
    margin-top: 22px;
  `}
`
export const SubTitle = styled.h2`
  font-size: 12px;
  font-family: SFProText;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.grey};
  margin-top: 20px;
  margin-bottom: 20px;

  ${media.sm`
    padding-bottom: 10px;
    font-size: 16px;
  `}
  ${media.md`
    padding-bottom: 15px;
    font-size: 18px;
  `}
  ${media.lg`
    padding-bottom: 15px;
    font-size: 18px;
  `}
`

export const Button = styled.div`
  width: 179px;
  height: 36px;
  padding: 8px 20px;
  border-radius: 18px;
  background-color: ${theme.gold};
  margin: 0 auto;
  cursor: pointer;

  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;

  ${media.sm`
    margin: 0;
  `}
  ${media.md`
    margin: 0;
  `}
  ${media.lg`
    margin: 0;
  `}
`

export const InfoText = styled.div`
  font-family: SFProText;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.grey};
  margin: 20px 0;
`

export const ContainerFlex = styled.div`
  display: block;
  ${media.sm`
    display: flex;
  `}
  ${media.md`
    display: flex;
  `}
  ${media.lg`
    display: flex;
  `}
`

export const ImageContainer = styled.div`
  min-height: 60px;
  display: inline-flex;
  margin: 0 auto;
`
