import styled from 'styled-components'

export const StepperContainer = styled.div`
  padding: 1rem 0rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  z-index: 3;
  position: relative;
  margin: 0px 0px 1px 0px;
`
