import styled from 'styled-components'

type StyledInputProps = {
  error?: boolean
}

export const TextArea = styled.textarea<StyledInputProps>`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 5px;
  background-color: #ffffff;
  border: solid 1px #cbcbcb;
  font-family: 'Roboto';
  padding: 0.8rem 1rem;
  font-family: 'Roboto';
  resize: none;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  &:focus {
    border-color: #c59d5f;
    outline: none;
    box-shadow: 0px 0px 2px 0px #c59d5f;
  }
  &::placeholder {
    color: #cbcbcb;
  }

  ${(props) => {
    return props.error ? 'border-color: red;' : null
  }};

  width: 100%;
`
