import styled from 'styled-components'
import media from '../../../../styles/media'
import theme from '../../../../styles/theme'

export const BoxWrapper = styled.div`
  display: flex;

  div {
    width: 100%;
    margin: 10px 5px;
    border: solid ${theme.lightGrey3} 1px;
    padding: 10px;
    border-radius: 7px;
    position: relative;

    h3 {
      padding: 5px 0 0 0;
      font-family: Roboto;
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${theme.lightGrey2};
      text-align: left;
      padding: 0;
    }

    p {
      color: ${theme.black};
      font-family: Roboto;
      font-size: 17px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      margin: 0;
    }

    .dot {
      height: 20px;
      width: 20px;
      background: #fff;
      border-radius: 50%;
      position: absolute;
      border: 1px ${theme.lightGrey3} solid;
      right: 4px;
      bottom: 4px;
    }
  }

  div.pressed {
    background: ${theme.lightGold};
    border: solid ${theme.gold} 1px;

    h3 {
      color: ${theme.gold};
    }

    p {
      color: ${theme.gold};
      font-weight: bold;
    }

    .dot {
      border: 1px ${theme.gold} solid;
      &::before {
        position: absolute;
        content: '';
        top: 3px;
        left: 3px;
        width: 12px;
        height: 12px;
        background: ${theme.gold};
        border-radius: 50%;
      }
    }
  }

  ${media.sm`
  display: flex;
    div{
      width: 45%;
      margin: 0 2.5%;
    }
  `};
`
export const TextSection = styled.div`
  margin-bottom: 29px;
  font-family: SFProText;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: start;
  color: ${theme.gold};
`