import { KeyboardEvent } from 'react'
import { ALPHA_NUMERIC_ONLY, DIGITS_ONLY } from './constants'

export const isIdentification = (identificationType: string): boolean => identificationType == 'C'
export const isPassport = (identificationType: string): boolean => identificationType == 'P'
export const isRUC = (identificationType: string): boolean => identificationType == 'R'

export const calculateLength = (identificationType: string) => {
  if (isIdentification(identificationType)) {
    return { min: 10, max: 10 }
  }
  if (isPassport(identificationType)) {
    return { min: 4, max: 16 }
  }
  return { min: 13, max: 13 }
}

const checkExpressions = (pressedKey: string, selectedIdType: string): boolean =>
  (isIdentification(selectedIdType) && DIGITS_ONLY.test(pressedKey)) ||
  (isPassport(selectedIdType) && ALPHA_NUMERIC_ONLY.test(pressedKey)) ||
  (isRUC(selectedIdType) && DIGITS_ONLY.test(pressedKey))

const checkLength = (identification: string, minlength: number, maxlength: number) => {
  return identification.length >= minlength && identification.length <= maxlength
}

export const keyPressIdentification = (event: KeyboardEvent<HTMLInputElement>, selectedIdType: string): string => {
  const pressedKey = event.key
  const { value } = event.currentTarget

  if (checkExpressions(pressedKey, selectedIdType)) {
    return value
  }
  event.preventDefault()
  return ''
}

export const validateIdentification = (identification: any, selectedIdType: string) => {
  if (isIdentification(selectedIdType) && !checkLength(identification, 10, 10)) {
    return {
      error: true,
      message: 'La cédula debe contener 10 números.',
    }
  }

  if (isPassport(selectedIdType) && !checkLength(identification, 4, 16)) {
    return {
      error: true,
      message: 'El pasaporte debe contener mínimo 4 y máximo 16 números/letras.',
    }
  }

  if (isRUC(selectedIdType) && !checkLength(identification, 13, 13)) {
    return {
      error: true,
      message: 'El RUC debe contener 13 números.',
    }
  }

  if (isIdentification(selectedIdType) && !isValidIdentification(identification)) {
    return {
      error: true,
      message: 'La cédula no es valida',
    }
  }

  return { error: false, message: '' }
}

const isValidIdentification = (identification: any): boolean => {
  let total = 0
  let longitud = identification.length
  let longcheck = longitud - 1

  if (identification !== '' && longitud === 10) {
    for (let i = 0; i < longcheck; i++) {
      if (i % 2 === 0) {
        let aux = identification.charAt(i) * 2
        if (aux > 9) aux -= 9
        total += aux
      } else {
        total += parseInt(identification.charAt(i))
      }
    }
    total = total % 10 ? 10 - (total % 10) : 0
    return identification.charAt(longitud - 1) == total
  }
  return false
}
