import styled from 'styled-components'
import media from '../../../styles/media'
import theme from '../../../styles/theme'
import { FormInputContainer, StyledInput, StyledLabel } from '../../Collapse/stepped/style'

const lineColor = '#d8d8d8'

export const CardInfoContainer = styled.div`
  padding-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  &.min-height-50 {
    min-height: 50vh;
  }
  ${media.lg`
  padding: 1rem 8%;
  `}

  .thin-line {
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;
    height: 1px;
    border: none;
    background-color: ${lineColor};
    ${media.md`
    width: 90%;
    &.mobile-only {
      display: none;
    }
    `}
    ${media.lg`
    width: 75%;
    `}
  }
`

export const StyledDisclaimerDiners = styled.p`
  padding-bottom: 10px;
  font-family: 'Roboto';
  font-size: 0.9rem;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 1px;
  text-align: center;
  color: ${theme.purpleBrown};
  margin: 2rem auto 0;
`

export const BrandsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const StyledLogo = styled.img`
  max-width: 100px;
  margin: 0 10px;
`

export const CollapseContainer = styled.div`
  border-top: 1px solid ${theme.camel};
  padding: 20px 0;
  ${media.md`
  display: none;
  `}
`

export const InfoSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: top;
  padding: 15px 0;
  text-align: center;
  margin-bottom: 20px;
  padding: 10px;

  &:last-child {
    border-bottom: none;
  }
`

export const InfoSide = styled.div`
  display: flex;
  flex-direction: column;

  hr {
    width: 15%;
    border: 1px solid #ededed;
    margin: 5px 0 10px;
  }
`

export const SectionTitle = styled.div`
  color: ${theme.camel};
  font-family: 'SFProText';
  font-size: 12.5px;
  text-transform: uppercase;
  letter-spacing: 0.69px;
  padding-bottom: 5px;
`

export const SectionText = styled.div`
  color: ${theme.grey};
  font-family: Roboto;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`

export const InfoContainer = styled.div`
  margin-right: 0px;
  margin-left: 0px;
  padding: 0;
`

export const Title = styled.h2`
  color: ${theme.gold};
  text-align: left;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  font-family: 'TimesNewRomanPSMT';
  font-size: 20px;
  margin-top: 20px;
  ${media.sm`
    text-align: left;
    font-size: 20px;
    margin-top: 0;
  `}
  ${media.md`
    text-align: left;
    font-size: 25px;
    margin-top: 0;
  `}
  ${media.lg`
    text-align: left;
    font-size: 28px;
    margin-top: 0;
  `}
`

type SubTitleProps = {
  weight?: string
  withoutMargin?: boolean
  fontSize?: string
  color?: string
  marginBottom?: string
  marginTop?: string
  paddingBottom?: string
}

export const SubTitle = styled.h2<SubTitleProps>` 
  font-size: 15px;
  font-family: 'Roboto';
  font-weight: ${(props: SubTitleProps) => {
    return props.weight ? props.weight : 'normal'
  }};
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props: SubTitleProps) => {
    return props.color ? props.color : theme.grey
  }};
  text-align: left;
  margin-bottom: ${(props: SubTitleProps) => {
    return props.withoutMargin ? '0' : (props.marginBottom ? props.marginBottom : '20px')
  }};
  margin-top: ${(props: SubTitleProps) => {
    return props.marginTop ? props.marginTop : '0'
  }};
  ${media.sm`
    padding-bottom: ${(props: SubTitleProps) => {
      return props.paddingBottom ? props.paddingBottom : '10px'
    }};
  `}
  ${media.md`
    padding-bottom: ${(props: SubTitleProps) => {
      return props.paddingBottom ? props.paddingBottom : '15px'
    }};
    font-size: 16px;
  `}
  ${media.lg`
    padding-bottom: ${(props: SubTitleProps) => {
      return props.paddingBottom ? props.paddingBottom : '15px'
    }};
    font-size: 16px;
  `}
  .fontSize12 {
    fontSize: 12px;
  }
`

export const Button = styled.div`
  width: 179px;
  height: 36px;
  padding: 8px 20px;
  border-radius: 18px;
  background-color: ${theme.gold};
  margin: 0 auto;
  cursor: pointer;

  font-family: Roboto;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #ffffff;

  ${media.sm`
    margin: 0;
  `}
  ${media.md`
    margin: 0;
  `}
  ${media.lg`
    margin: 0;
  `}
`

export const InfoText = styled.div`
  font-family: Roboto;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.grey};
  margin: 20px 0;
`

export const ContainerFlex = styled.div`
  display: block;
  ${media.sm`
    display: flex;
  `}
  ${media.md`
    display: flex;
  `}
  ${media.lg`
    display: flex;
  `}
`

type WithoutPContainerProps = {
  visible?: boolean
}

export const WithoutPContainer = styled.div`
  padding: 0;
  text-align: center;
  display: ${(props: WithoutPContainerProps) => {
    return props.visible ? 'none' : 'block'
  }};
  margin-top: 20px;

  ${media.md`
    padding: 0;
  `}
  .alignMargin {
    textAlign: left;
    marginBottom: 20px;
  }
`

export const Image = styled.img`
  max-width: 60%;
  ${media.sm`
    max-width: 100%;
  `}
`

type InputContainerBlockProps = {
  visible?: boolean
}
export const FormInputContainerBlock = styled(FormInputContainer as any)`
  text-align: left;
  margin-bottom: 5px;
  display: ${(props: InputContainerBlockProps) => {
    return props.visible ? 'none' : 'block'
  }};
`

export const StyledLabelStep = styled(StyledLabel as any)`
  color: ${theme.lightGrey2};
`

export const StyledInputStep = styled(StyledInput as any)`
  border-color: ${theme.lightGrey3};
  font-family: Roboto;
  font-size: 15px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #2f2f2f;
  height: 42.38px;
  padding: 0.8rem 2.8rem 0.8rem 1rem;

  &:disabled {
    color: #9a9a9a;
    cursor: default;
    background-color: #f2f2f2;
    border-color: #e6e6e6;
  }

  &:focus {
    box-shadow: 0 0 4px 0 #c69d5f;
    border: solid 1px #c69d5f;
  }
`

type ImageStyled = {
  image?: string
}
export const Extra = styled.span`
  width: 21px;
  height: 20px;
  position: absolute;
  top: 13px;
  right: 10px;
  background-repeat: no-repeat;
  background-image: url(${(props: ImageStyled) => {
    return props.image ? props.image : null
  }});
`

type CheckProps = {
  visible?: boolean
}
export const Check = styled.span<CheckProps>`
  width: 22px;
  height: 22px;
  transform: rotate(45deg);
  position: absolute;
  top: 10px;
  right: 5px;
  display: ${(props: CheckProps) => {
    return props.visible ? 'inline-block' : 'none'
  }};

  &:before {
    position: absolute;
    width: 3px;
    height: 9px;
    background-color: ${theme.gold};
    left: 11px;
    top: 6px;
    content: ' ';
  }

  &:after {
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: ${theme.gold};
    left: 8px;
    top: 12px;
    content: '';
  }
`