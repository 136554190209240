import React, { KeyboardEvent, useEffect, useState } from 'react'
import MembershipData from '../../../../entities/MembershipData'
import Select from 'react-select'
import { RequestGetter } from '../../../../helpers/request'
import { toast } from 'react-toastify'
import { BackButton, ButtonContainer, CustomButton, InputContainer, StyledError, StyledSelect } from '../../../Collapse/stepped/style'
import { Check, FormInputContainerBlock, Image, InfoContainer, StyledInputStep, StyledLabelStep, SubTitle, Title, WithoutPContainer } from '../style'
import { TextArea } from './style'
import { City } from '../../types'

const GRANDETABLE_CARD = require('../../../../assets/images/general/grandetable-card.png')

type Step3Props = {
  membershipData: MembershipData
  setMembershipData: (membershipData: MembershipData) => void
  setStep: (step: number) => void
  cities: City[]
}

type StepErrors = {
  city?: string
  mainStreet?: string
  secondaryStreet?: string
  numeration?: string
  reference?: string
}

export default function Step3(props: Step3Props) {
  const { membershipData, setMembershipData, setStep, cities } = props
  const [cityList, setCities] = useState<City[]>([])
  const [selectedCity, setSelectedCity] = useState<City>(cities[0])
  const [errors, setErrors] = useState<StepErrors>({})
  const [customerMainStreet, setCustomerMainStreet] = useState('')
  const [isMainStreetCorrect, setIsMainStreetCorrect] = useState(false)
  const [customerSecondaryStreet, setCustomerSecondaryStreet] = useState('')
  const [isSecondaryStreetCorrect, setIsSecondaryStreetCorrect] = useState(false)
  const [customerNumeration, setCustomerNumeration] = useState('')
  const [isNumerationCorrect, setIsNumerationCorrect] = useState(false)
  const [customerReference, setCustomerReference] = useState('')

  useEffect(() => {
    setCities(cities)
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    populateFields()
  }, [membershipData])

  const populateFields = () => {
    if (membershipData) {
      const { mainStreet, secondaryStreet, numeration, city, reference } = membershipData
      mainStreet && setCustomerMainStreet(mainStreet)
      secondaryStreet && setCustomerSecondaryStreet(secondaryStreet)
      numeration && setCustomerNumeration(numeration)
      reference && setCustomerReference(reference)
      city && setSelectedCity(cities.filter((cityItem) => cityItem.value === city)[0])
    }
  }

  const validateForm = () => {
    let result: boolean = true
    let formErrors: StepErrors = {}

    if (customerMainStreet.length < 1) {
      formErrors.mainStreet = 'Campo Requerido'
      result = false
    }

    if (customerNumeration.length < 1) {
      formErrors.numeration = 'Campo Requerido'
      result = false
    }

    if (customerSecondaryStreet.length < 1) {
      formErrors.secondaryStreet = 'Campo Requerido'
      result = false
    }

    if (selectedCity === undefined) {
      formErrors.city = 'Campo Requerido'
      result = false
    }

    if (customerReference.length < 1) {
      formErrors.reference = 'Campo Requerido'
      result = false
    }

    setErrors(formErrors)
    return result
  }

  const handleCityChange = (city: City) => {
    setSelectedCity(city)
  }

  const handleMainStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerMainStreet(e.currentTarget.value)
  }

  const handleKeyUpMainStreet = () => {
    if (customerMainStreet.length >= 5) {
      setIsMainStreetCorrect(true)
    } else {
      setIsMainStreetCorrect(false)
    }
  }

  const handleNumerationChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerNumeration(e.currentTarget.value)
  }

  const handleSecondaryStreetChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerSecondaryStreet(e.currentTarget.value)
  }

  const handleReferenceChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCustomerReference(e.currentTarget.value)
  }

  const handleKeyUpNumeration = () => {
    if (customerNumeration.length >= 2) {
      setIsNumerationCorrect(true)
    } else {
      setIsNumerationCorrect(false)
    }
  }

  const handleKeyUpSecondStreet = () => {
    if (customerSecondaryStreet.length >= 5) {
      setIsSecondaryStreetCorrect(true)
    } else {
      setIsSecondaryStreetCorrect(false)
    }
  }

  const submitForm = () => {
    if (validateForm()) {
      handleNextStep()
    }
  }

  const handleNextStep = () => {
    const params = {
      mainStreet: customerMainStreet,
      numeration: customerNumeration,
      city: selectedCity.value,
      secondaryStreet: customerSecondaryStreet,
      reference: customerReference,
      idn: membershipData.identification,
    }

    RequestGetter('POST', 'registerAddress', params)
      .then(() => {
        setMembershipData({
          ...membershipData,
          mainStreet: customerMainStreet,
          numeration: customerNumeration,
          city: selectedCity.value,
          secondaryStreet: customerSecondaryStreet,
          reference: customerReference,
        })
        setStep(4)
      })
      .catch((error: any) => {
        toast('Ocurrió un error al intentar registrar los datos de contacto.')
      })
  }

  return (
    <>
      <Title>Datos para emisión</Title>
      <SubTitle>Selecciona los datos para la entrega de tu tarjeta.</SubTitle>
      <FormInputContainerBlock>
        <StyledLabelStep>Ciudad</StyledLabelStep>
        <InputContainer>
          <Select
            placeholder="Seleccione la ciudad"
            isSearchable={false}
            options={cities}
            error={errors.city !== undefined}
            styles={{
              control: (base) => ({ ...base, color: 'white' }),
              option: (base) => ({ ...base, color: 'black' }),
            }}
            className="select-control-container"
            classNamePrefix="select-control"
            onChange={(currentCity: City) => {
              handleCityChange(currentCity)
            }}
            value={selectedCity}
          />
          {errors.city && <StyledError>{errors.city}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <FormInputContainerBlock>
        <StyledLabelStep>Calle Principal</StyledLabelStep>
        <InputContainer>
          <StyledInputStep
            placeholder="Ingresa la calle principal"
            error={errors.secondaryStreet !== undefined}
            onChange={handleMainStreetChange}
            onKeyUp={handleKeyUpMainStreet}
            maxLength={40}
            value={customerMainStreet}
          />
          <Check visible={isMainStreetCorrect} />
          {errors.mainStreet && <StyledError>{errors.mainStreet}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <FormInputContainerBlock>
        <StyledLabelStep>Numeración</StyledLabelStep>
        <InputContainer>
          <StyledInputStep
            placeholder="Numeración"
            error={errors.numeration !== undefined}
            onChange={handleNumerationChange}
            onKeyUp={handleKeyUpNumeration}
            maxLength={20}
            value={customerNumeration}
          />
          <Check visible={isNumerationCorrect} />
          {errors.numeration && <StyledError>{errors.numeration}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <FormInputContainerBlock>
        <StyledLabelStep>Calle secundaria</StyledLabelStep>
        <InputContainer>
          <StyledInputStep
            placeholder="Ingresa la calle secundaria"
            error={errors.secondaryStreet !== undefined}
            onChange={handleSecondaryStreetChange}
            onKeyUp={handleKeyUpSecondStreet}
            maxLength={40}
            value={customerSecondaryStreet}
          />
          <Check visible={isSecondaryStreetCorrect} />
          {errors.secondaryStreet && <StyledError>{errors.secondaryStreet}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <FormInputContainerBlock>
        <StyledLabelStep>Referencia</StyledLabelStep>
        <InputContainer>
          <TextArea
            placeholder="Ingresa un punto de referencia que nos ayude a llegar a tu domicilio"
            error={errors.reference !== undefined}
            onChange={handleReferenceChange}
            maxLength={50}
            rows="3"
            value={customerReference}
          />
          {errors.reference && <StyledError>{errors.reference}</StyledError>}
        </InputContainer>
      </FormInputContainerBlock>
      <ButtonContainer>
        <CustomButton id="GRTB_CONT_ADD" className="GRTB_SLEC_MEMB" onClick={submitForm}>
          Continuar
        </CustomButton>
        <BackButton id="GRTB_MEMBR_P3_REG" onClick={() => setStep(2)}>REGRESAR</BackButton>
      </ButtonContainer>
    </>
  )
}
