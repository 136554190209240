import React from 'react'

import { StyledRow } from '../components/StylesPages/membershipStyle'
import Layout from '../components/Layout'
import MembershipForm from '../components/MembershipForm'

export default () => {
  return (
    <Layout themeType="dark" currentPage={'membership'}>
      <StyledRow className="container-fluid" style={{ margin: '0px', padding: '0px' }}>
        <MembershipForm />
      </StyledRow>
    </Layout>
  )
}
