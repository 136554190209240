import styled from 'styled-components'
import media from '../../../../styles/media'
import theme from '../../../../styles/theme'

type CollapseProps = {
  isOpen: boolean
  checked?: boolean
}

export const Check = styled.span`
  display: inline-block;
  width: 22px;
  height: 22px;
  transform: rotate(45deg);

  &:before {
    position: absolute;
    width: 3px;
    height: 9px;
    background-color: #fff;
    left: 11px;
    top: 6px;
    content: ' ';
  }

  &:after {
    position: absolute;
    width: 3px;
    height: 3px;
    background-color: #fff;
    left: 8px;
    top: 12px;
    content: '';
  }
`

export const HeadContainer = styled.div`
  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  border: 1.9px solid ${theme.gold};
  border-color: ${(props: CollapseProps) => (props.checked === false ? (props.isOpen ? theme.gold : '#dddddd') : theme.gold)};
  background-color: ${(props: CollapseProps) => (props.checked === false ? (props.isOpen ? theme.mainColor : theme.mainColor) : theme.gold)};
`

export const StepperHeader = styled.div`
  display: grid;
  margin-bottom: 0;
  padding: 0.5rem 0rem;
  align-items: center;
  text-align: center;
`

export const Number = styled.span`
  font-size: 15px;
  font-weight: bold;
  line-height: 1.47;
  text-align: center;
  color: ${(props: CollapseProps) => (props.isOpen ? theme.gold : theme.lightGrey3)};
`

export const TitleContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-top: 5px;
  top: 0;
  ${media.sm`  
    margin: 0 auto;
    position: relative;
  `};
`

type TitleProps = {
  isOpen: boolean
}

export const Title = styled.span`
  font-family: Roboto;
  font-size: 11px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: ${theme.gold};
  display: ${(props: TitleProps) => (props.isOpen ? 'block' : 'none')};
  ${media.sm`  
    display: block;
  `};
`
