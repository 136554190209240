import React, { KeyboardEvent, useEffect, useState } from 'react'
import Select from 'react-select'
import { toast } from 'react-toastify'
import MembershipData from '../../../../entities/MembershipData'
import { RequestGetter } from '../../../../helpers/request'
import { BackButton, ButtonContainer, CustomButton, InputContainer, StyledError, YesNoQuestion } from '../../../Collapse/stepped/style'
import { ALPHA_NUMS, DIGITS, FAC_IDENTIFICATION_OPTIONS } from '../../constants'
import { IdentificationType } from '../../types'
import { calculateLength, isIdentification, keyPressIdentification, validateIdentification } from '../../../../utils/validateIdentification'
import { Check, Extra, FormInputContainerBlock, StyledInputStep, StyledLabelStep, SubTitle, Title, WithoutPContainer } from '../style'
import { ButtonQuestion } from './style'

const GRANDETABLE_CARD = require('../../../../assets/images/general/grandetable-card.png')
const EMAIL_ICON = require('../../../../assets/images/general/mail-icon.svg')
const PHONE_ICON = require('../../../../assets/images/general/phone-icon.svg')

type Step4Props = {
  membershipData: MembershipData
  setMembershipData: (membershipData: MembershipData) => void
  setStep: (step: number) => void
}

type StepErrors = {
  facIdentificationType?: string
  facIdentification?: string
  facName?: string
  facLastName?: string
  facPhone?: string
  facEmail?: string
  facCity?: string
  facMainStreet?: string
  facSecondaryStreet?: string
  facNumeration?: string
  address?: string
}

type City = {
  name: string
  id: number
}

export default function Step4(props: Step4Props) {
  const { membershipData, setMembershipData, setStep } = props
  const [selectedFacIdentificationType, setSelectedFacIdentificationType] = useState<IdentificationType>({ value: 'C', label: 'Cédula' })
  const [customerFacIdentification, setCustomerFacIdentification] = useState('')
  const [isFacIdentificationCorrect, setIsFacIdentificationCorrect] = useState(false)
  const [customerFacName, setCustomerFacName] = useState('')
  const [isFacNameCorrect, setIsFacNameCorrect] = useState(false)
  const [customerFacLastName, setCustomerFacLastName] = useState('')
  const [isFacLastNameCorrect, setIsFacLastNameCorrect] = useState(false)
  const [customerFacPhone, setCustomerFacPhone] = useState('')
  const [customerFacEmail, setCustomerFacEmail] = useState('')
  const [errors, setErrors] = useState<StepErrors>({})
  const [customerAddress, setCustomerAddress] = useState('')
  const [isVisible, setIsVisible] = useState(false)
  const [isYesOptionSelected, setIsYesOptionSelected] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    populateFields()
  }, [membershipData])

  const populateFields = () => {
    if (membershipData) {
      const { identification, identificationType, name, lastName, email, phone, isFormVisible, city, mainStreet, secondaryStreet, numeration } = membershipData
      identificationType &&
        setSelectedFacIdentificationType({
          value: identificationType,
          label: isIdentification(identificationType) ? 'Cédula' : 'Pasaporte',
        })
      identification && setCustomerFacIdentification(identification)
      name && setCustomerFacName(name)
      lastName && setCustomerFacLastName(lastName)
      phone && setCustomerFacPhone(phone)
      email && setCustomerFacEmail(email)
      isFormVisible && setIsVisible(isFormVisible)
      city && setCustomerAddress(mainStreet + ' ' + numeration + ' y ' + secondaryStreet)
    }
  }

  const validateForm = () => {
    let result: boolean = true
    let formErrors: StepErrors = {}

    if (customerFacName.length < 1) {
      formErrors.facName = 'Campo Requerido'
      result = false
    }

    if (customerFacLastName.length < 1) {
      formErrors.facLastName = 'Campo Requerido'
      result = false
    }

    if (customerFacIdentification.length < 1) {
      formErrors.facIdentification = 'Campo Requerido'
      result = false
    } else {
      const validIdentification = validateIdentification(customerFacIdentification, selectedFacIdentificationType.value)
      if (validIdentification.error){
        formErrors.facIdentification = validIdentification.message
        result = false
      }
    }

    if (customerFacPhone.length < 1) {
      formErrors.facPhone = 'Campo Requerido'
      result = false
    } else if (!/[0-9]{10}$/.test(customerFacPhone)) {
      formErrors.facPhone = 'El número de teléfono debe contener máximo 10 dígitos.'
      result = false
    }

    if (customerFacEmail.length < 1) {
      formErrors.facEmail = 'Campo Requerido'
      result = false
    } else if (!/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(customerFacEmail)) {
      formErrors.facEmail = 'El email no es válido.'
      result = false
    }

    if (customerAddress.length < 1) {
      formErrors.address = 'Campo Requerido'
      result = false
    }

    setErrors(formErrors)
    return result
  }

  const checkLength = (identification: string, minlength: number, maxlength: number) => {
    return identification.length >= minlength && identification.length <= maxlength
  }

  const isFormVisible = (isVisible: boolean) => {
    if (!isVisible) {
      const { name, lastName, identification, identificationType, phone, email, mainStreet, secondaryStreet, numeration, city } = membershipData
      name && setCustomerFacName(name)
      lastName && setCustomerFacLastName(lastName)
      identificationType &&
        setSelectedFacIdentificationType({
          value: identificationType,
          label: isIdentification(identificationType) ? 'Cédula' : 'Pasaporte',
        })
      identification && setCustomerFacIdentification(identification)
      phone && setCustomerFacPhone(phone)
      email && setCustomerFacEmail(email)
      city && setCustomerAddress(mainStreet + ' ' + numeration + ' y ' + secondaryStreet)
    } else {
      setCustomerFacName('')
      setCustomerFacLastName('')
      setSelectedFacIdentificationType('')
      setCustomerFacIdentification('')
      setCustomerFacPhone('')
      setCustomerFacEmail('')
      setCustomerAddress('')
    }
    setIsVisible(isVisible)
    setIsYesOptionSelected(!isVisible)
  }

  const handleKeyUpFacIdentification = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const validate: { [key: string]: boolean } = {
      R: validateR(),
      C: validateC(),
      P: validateP(),
    }

    setIsFacIdentificationCorrect(validate[selectedFacIdentificationType.value])
  }

  const validateR = () => {
    const regexR = new RegExp(`${DIGITS}$`)
    return regexR.test(customerFacIdentification) && customerFacIdentification.toString().length == 13
  }

  const validateC = () => {
    const regexC = new RegExp(`${DIGITS}$`)
    return regexC.test(customerFacIdentification) && customerFacIdentification.toString().length == 10
  }

  const validateP = () => {
    const regexP = new RegExp(`${ALPHA_NUMS}$`)
    return regexP.test(customerFacIdentification) && customerFacIdentification.toString().length >= 4 && customerFacIdentification.toString().length <= 16
  }

  const handleKeyUpFacName = () => {
    if (customerFacName.length >= 2) {
      setIsFacNameCorrect(true)
    } else {
      setIsFacNameCorrect(false)
    }
  }
  const handleKeyPressPhone = (event: KeyboardEvent<HTMLInputElement>) => {
    const pressedKey = event.key
    const regex = new RegExp(`${DIGITS}$`)

    if (regex.test(pressedKey)) {
      setCustomerFacPhone(event.currentTarget.value)
      return
    }
    event.preventDefault()
  }

  const handleFacNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerFacName(e.currentTarget.value)
  }

  const handleRazonChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerFacName(e.currentTarget.value)
    setCustomerFacLastName(e.currentTarget.value)
  }

  const handleFacLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerFacLastName(e.currentTarget.value)
  }

  const handleFacPhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerFacPhone(e.currentTarget.value)
  }

  const handleFacEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerFacEmail(e.currentTarget.value)
  }

  const handleAddressChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerAddress(e.currentTarget.value)
  }

  const submitForm = () => {
    if (validateForm()) {
      handleNextStep()
    }
  }

  const submitWithOutValidation = () => {
    isFormVisible(false)
  }

  const handleNextStep = () => {
    const { identification } = membershipData
    const params = {
      idn: identification,
      facName: customerFacName,
      facLastname: customerFacLastName,
      facIdentificationType: selectedFacIdentificationType.value,
      facIdn: customerFacIdentification,
      facPhone: customerFacPhone,
      facEmail: customerFacEmail,
      address: customerAddress,
    }

    RequestGetter('POST', 'invoiceregister', params)
      .then(() => {
        setMembershipData({
          ...membershipData,
          facName: customerFacName,
          facLastName: customerFacLastName,
          facIdentificationType: selectedFacIdentificationType.value,
          facIdentification: customerFacIdentification,
          facPhone: customerFacPhone,
          facEmail: customerFacEmail,
          isFormVisible: isVisible,
          address: customerAddress,
        })
        setStep(5)
      })
      .catch((error: any) => {
        if (error.response.status == 401) {
          toast('Cédula no válida. Intente nuevamente.')
          return
        }
        toast('Ocurrió un error al intentar registrar los datos de facturación.')
      })
  }

  return (
    <>
      <Title>Datos de facturación</Title>
      <SubTitle>¿Deseas usar los mismos datos para tu factura?</SubTitle>
      <YesNoQuestion className="col-md-12">
        <ButtonQuestion id="GRTB_MEMBR_P4_SI" selected={isYesOptionSelected} onClick={() => submitWithOutValidation()}>
          SI
        </ButtonQuestion>
        <ButtonQuestion id="GRTB_MEMBR_P4_NO" selected={isVisible} onClick={() => isFormVisible(true)}>
          NO
        </ButtonQuestion>
      </YesNoQuestion>
      <br />
      <br />
      <WithoutPContainer className="col-md-12" visible={!isVisible}>
        <FormInputContainerBlock>
          <StyledLabelStep>Tipo de Identificación</StyledLabelStep>
          <InputContainer>
            <Select
              placeholder="Seleccione Tipo"
              error={errors.facIdentificationType !== undefined}
              isSearchable={false}
              styles={{
                control: (base) => ({ ...base, color: 'white' }),
                option: (base) => ({ ...base, color: 'black' }),
              }}
              className="select-control-container"
              classNamePrefix="select-control"
              options={FAC_IDENTIFICATION_OPTIONS}
              value={selectedFacIdentificationType}
              onChange={(currentType: IdentificationType) => {
                setSelectedFacIdentificationType(currentType)
                setCustomerFacIdentification('')
              }}
            />
            {errors.facIdentificationType !== '' && <StyledError>{errors.facIdentificationType !== ''}</StyledError>}
          </InputContainer>
        </FormInputContainerBlock>
        <FormInputContainerBlock>
          <StyledLabelStep>Número de identificación</StyledLabelStep>
          <InputContainer>
            <StyledInputStep
              placeholder="Ingresa el número de identificación"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCustomerFacIdentification(e.target.value)}
              error={errors.facIdentification !== undefined}
              onKeyUp={handleKeyUpFacIdentification}
              maxLength={calculateLength(selectedFacIdentificationType.value).max}
              onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => keyPressIdentification(e, selectedFacIdentificationType.value)}
              value={customerFacIdentification}
            />
            <Check visible={isFacIdentificationCorrect} />
            {errors.facIdentification && <StyledError>{errors.facIdentification}</StyledError>}
          </InputContainer>
        </FormInputContainerBlock>
        <FormInputContainerBlock visible={!(selectedFacIdentificationType.value == 'R')}>
          <StyledLabelStep>Razón Social</StyledLabelStep>
          <InputContainer>
            <StyledInputStep
              placeholder="Ingresa su razón social"
              onChange={handleRazonChange}
              error={errors.facName !== undefined}
              onKeyUp={handleKeyUpFacName}
              value={customerFacName}
            />
            <Check visible={isFacNameCorrect} />
            {errors.facName && <StyledError>{errors.facName}</StyledError>}
          </InputContainer>
        </FormInputContainerBlock>
        <FormInputContainerBlock visible={!(selectedFacIdentificationType.value != 'R')}>
          <StyledLabelStep>Nombre</StyledLabelStep>
          <InputContainer>
            <StyledInputStep
              placeholder="Ingresa el nombre"
              onChange={handleFacNameChange}
              error={errors.facName !== undefined}
              onKeyUp={handleKeyUpFacName}
              value={customerFacName}
            />
            <Check visible={isFacNameCorrect} />
            {errors.facName && <StyledError>{errors.facName}</StyledError>}
          </InputContainer>
        </FormInputContainerBlock>
        <FormInputContainerBlock visible={!(selectedFacIdentificationType.value != 'R')}>
          <StyledLabelStep>Apellido</StyledLabelStep>
          <InputContainer>
            <StyledInputStep
              placeholder="Ingresa el apellido"
              onChange={handleFacLastNameChange}
              error={errors.facLastName !== undefined}
              onKeyUp={() => setIsFacLastNameCorrect(customerFacName.length >= 2)}
              value={customerFacLastName}
            />
            <Check visible={isFacLastNameCorrect} />
            {errors.facLastName && <StyledError>{errors.facLastName}</StyledError>}
          </InputContainer>
        </FormInputContainerBlock>
        <br />
        <FormInputContainerBlock>
          <StyledLabelStep>Teléfono de contacto</StyledLabelStep>
          <InputContainer>
            <StyledInputStep
              placeholder="Ingresa el teléfono de contacto"
              onChange={handleFacPhoneChange}
              onKeyPress={handleKeyPressPhone}
              error={errors.facPhone !== undefined}
              maxLength={10}
              value={customerFacPhone}
            />
            <Extra image={PHONE_ICON} />
            {errors.facPhone && <StyledError>{errors.facPhone}</StyledError>}
          </InputContainer>
        </FormInputContainerBlock>
        <FormInputContainerBlock>
          <StyledLabelStep>Correo electrónico</StyledLabelStep>
          <InputContainer>
            <StyledInputStep placeholder="Ingresa el correo electrónico" onChange={handleFacEmailChange} error={errors.facEmail !== undefined} value={customerFacEmail} />
            <Extra image={EMAIL_ICON} />
            {errors.facEmail && <StyledError>{errors.facEmail}</StyledError>}
          </InputContainer>
        </FormInputContainerBlock>
        <br />
        <FormInputContainerBlock>
          <StyledLabelStep>Dirección</StyledLabelStep>
          <InputContainer>
            <StyledInputStep
              placeholder="Ingresa tu dirección completa"
              error={errors.address !== undefined}
              onChange={handleAddressChange}
              maxLength={60}
              value={customerAddress}
            />
            {errors.address && <StyledError>{errors.address}</StyledError>}
          </InputContainer>
        </FormInputContainerBlock>
      </WithoutPContainer>
      <ButtonContainer>
        <CustomButton id="GRTB_CONT_DF" className="GRTB_SLEC_MEMB" onClick={submitForm}>
          Continuar
        </CustomButton>
        <BackButton id="GRTB_MEMBR_P4_REG" onClick={() => setStep(3)}>REGRESAR</BackButton>
      </ButtonContainer>
    </>
  )
}
