import styled from 'styled-components'
import media from '../../../../styles/media'
import theme from '../../../../styles/theme'

export const SliderContainer = styled.div`
  background-color: #f5f5f5;
  margin: 0px;
  display: block;
  width: 100%;

  ${media.sm`
    display: none;
  `}

  .slick-slider {
    .slick-list {
      margin: 0;
    }
    .slick-dots {
      bottom: 0;
      padding-bottom: 10px;
      ${media.sm`
      padding-bottom: 20px;
      `}
      ${media.lg`
      padding-bottom: 30px;
      `}
      li {
        button {
          &:before {
            color: rgba(255, 255, 255, 0.7);
            font-size: 14px;
            ${media.md`
            opacity: 1; 
            `}
          }
        }
        &.slick-active {
          button {
            &:before {
              color: ${theme.secondaryColor};
              font-size: 18px;
            }
          }
        }
      }
    }

    .slick-prev {
      left: 0;
      ${media.sm`
      left: 20px;
      `}
      ${media.md`
      left: 50px;
      `}
      ${media.lg`
      left: 80px;
      `}
    }
    .slick-next {
      right: 0;
      ${media.sm`
      right:20px;
      `}
      ${media.md`
      right: 50px;
      `}
      ${media.lg`
      right: 80px;
      `}
    }
  }
`

export const SliderItem = styled.div`
  img {
    margin: auto;
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
`

export const Title = styled.h2`
  color: ${theme.gold};
  margin: 14px 47px;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`

export const InfoSide = styled.div`
  hr {
    width: 15%;
    border: 1px solid #ededed;
    margin: 5px 0 10px;
  }
`
export const SectionText = styled.div`
  color: ${theme.grey};
  margin: 12px 79px;
  font-family: SFProText;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
`
