import styled from 'styled-components'
import theme from '../../../../styles/theme'

type StyledButtonProps = {
  selected: boolean
}

export const ButtonQuestion = styled.button<StyledButtonProps>`
  width: 140px;
  margin: 0px 10px;
  padding: 9px 17px 10px;
  border-radius: 17.5px;
  border: solid 1px
    ${(props: StyledButtonProps) => {
      return props.selected ? theme.gold : theme.lightGrey3
    }};
  background-color: ${(props: StyledButtonProps) => {
    return props.selected ? theme.gold : theme.mainColor
  }};
  color: ${(props: StyledButtonProps) => {
    return props.selected ? theme.mainColor : theme.lightGrey2
  }};

  ${(props: StyledButtonProps) => {
    if (props.selected) {
      return `
        box-shadow: 0 0 4px 0 ${theme.gold};
      `
    }
    return null
  }}
`
