import React from 'react'
import { ToastContainer } from 'react-toastify'
import { StepperContainer } from './style'
import StepItem from '../StepItem'

type CollapseProps = {
  step: number
}
export default function Stepper({ step }: CollapseProps) {
  return (
    <StepperContainer>
      <StepItem numList={1} isOpen={step === 2} currentStep={step} />
      <StepItem numList={2} isOpen={step === 3} currentStep={step} />
      <StepItem numList={3} isOpen={step === 4} currentStep={step} />
      <StepItem numList={4} isOpen={step === 5} currentStep={step} />
      <ToastContainer position="bottom-right" autoClose={5000} hideProgressBar newestOnTop closeOnClick rtl={false} draggable={true} pauseOnHover />
    </StepperContainer>
  )
}
