import styled from 'styled-components'
import theme from '../../../../styles/theme'

type TrProps = {
  facIdentificationType?: string
}
type TdProps = {
  width?: string
  textAling?: string
}
type HrProps = {
  color?: string
}

export const HrColor = styled.hr<HrProps>`
  border-top-color: ${(props: HrProps) => {
    return props.color ? props.color : 'black'
  }};
  border-bottom: none;
  border-right: none;
  border-left: none;
`

export const Table = styled.table`
  color: ${theme.grey};
  width: 100%;
`

export const TableTr = styled.tr`
  height: 25px;
  .monthlyTable {
    background: ${theme.lightGold2};
    height: 30px;
  }
  .lastnameDisplay {
    display: ${(props: TrProps) => props.facIdentificationType=='R' ? 'none' : 'contents'}
  }
`

export const TableTd = styled.td<TdProps>`
  text-align: ${(props: TdProps) => {
    return props.textAling ? props.textAling : 'left'
  }};
  width: ${(props: TdProps) => {
    return props.width ? props.width : '50%'
  }};
  .monthyOpt {
    padding: 5px 8px;
    fontSize: 13px;
  }
  .monthyCost {
    padding: 5px 8px;
    fontWeight: bold;
  }
`
