import styled from 'styled-components'
import media from '../../styles/media'
import theme from '../../styles/theme'
import LazyImage from '../LazyImage'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  padding-top: 0;
  ${media.md`  
    padding-top: 1rem;
  `};
`

export const StyledRow = styled.div`
  margin: 25px 0;
`

export const Title = styled.h1`
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  letter-spacing: 3.7px;
  color: ${theme.purpleBrown};
  margin-top: 26px;
`

export const StyledImg = styled.img`
  height: auto;
  width: 100%;
  ${media.sm`  
    height: 340px;
    width: 340px;
  `};
`

export const ImgContainer = styled.div`
  padding-top: 20px;
`

export const ArticleWrapper = styled.div`
  color: #333;
  padding-top: 20px;

  p.date {
    padding-top: 15px;
    text-transform: uppercase;
    color: grey;
    margin-bottom: 10px;
  }

  h1.title {
    color: #000;
    font-size: 18px;
    margin-bottom: 0;
    line-height: 15px;
    span {
      font-size: 16px;
    }
  }

  h3.small_title {
    color: #000;
    font-size: 16px;
    margin-bottom: 0;
    line-height: 10px;
  }

  p.autor {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 11px;
  }
  p.share {
    font-size: 14px;
    a {
      vertical-align: top;
      padding-left: 5px;

      img {
        width: 20px;
      }
    }
  }
`

export const Image = styled(LazyImage)`
  max-width: 100%;
`

export const ArticlesSection = styled.div`
  margin-top: 20px;
  /* position: fixed;
  top: 0;
  right: 0; */

  h3 {
    font-weight: 400;
  }
`

export const BreadCumb = styled.div`
  span {
    font-size: 16px;
  }
`

export const Text = styled.p`
  color: ${theme.purpleBrown};
  font-size: 15px;
  font-weight: 300;
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 40px;
`

export const CollapseListContainer = styled.div`
  background: ${theme.lightGray};
  margin-bottom: 40px;
`
