import DatePicker from 'react-datepicker'
import styled from 'styled-components'

type StyledInputProps = {
  error?: boolean
}

export const StyledDatePickerHeader = styled.div`
  margin: 10px;
  display: flex;
  justifycontent: center;

  .prev-btn {
    color: #ffffff;
    background: none;
    border: none;
    &.left {
      margin: auto 7px auto 0;
    }
    &.right {
      margin: auto 0 auto 7px;
    }
  }

  .select-control-container {
    margin: 0 3px;
    .select-control__control {
      min-height: 20px;
      .select-control__value-container {
        padding: 2px 33px;
        .select-control__placeholder {
          left: 1%;
        }
        .select-control__single-value {
          left: 7px;
        }
      }
      .select-control__indicators {
        div {
          color: rgb(51, 51, 51);
          padding: 0;
        }
        span {
          background-color: transparent;
        }
      }
    }
  }
`
